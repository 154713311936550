<!--新建邮寄快递运费模板组件-->
<template>
  <el-form class="form-add-new width-all"
           :model="dataMap"
           ref="formNameAddNew"
           label-width="160px"
           v-loading="loading">
    <el-form-item label="模板名称" class="form-item" :rules="formRules.required" prop="name">
      <el-input v-model="dataMap.name" ></el-input>
    </el-form-item>
    <el-form-item label="发货时间" class="form-item" :rules="formRules.required" prop="shipTime">
      <el-select v-model="dataMap.shipTime"
                 placeholder="请选择">
        <el-option
          v-for="item in deliveryTimeList"
          :disabled="item.disabled"
          :key="item.item"
          :label="item.value"
          :value="item.id">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="发货地区" class="form-item" :rules="formRules.required" prop="addrJson.area">
      <el-cascader class="width-all"
                   ref="shipAddressValue"
                   :key="dataMap.addrJson.area"
                   style="width: 300px;"
                   placeholder="选择发货地区"
                   clearable
                   v-model="shipAddressValue"
                   @change="changeSelectRegion"
                   :props="{checkStrictly:false,expandTrigger: 'hover',clearable:true,lazy: true,value:'id',label:'name',lazyLoad}"></el-cascader>
    </el-form-item>
    <el-form-item label="邮费设置" class="form-item" :rules="formRules.required" prop="isFree">
      <el-radio-group v-model="dataMap.isFree">
        <el-radio :label="item.id" v-for="(item, index) in isPostageFreeArray" :key="index" @change="cutIsFree">{{item.name}}</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="运送方式" class="form-item" :rules="formRules.required">
      <el-radio checked>快递</el-radio>
    </el-form-item>
    <el-form-item label="计费方式" class="form-item" :rules="formRules.required" prop="isFree">
      <el-radio-group v-model="dataMap.billingType">
        <el-radio :label="item.id" v-for="(item, index) in isPostageBillingTypeArray" :key="index">{{item.name}}</el-radio>
      </el-radio-group>
    </el-form-item>
    <template v-if="dataMap.isFree === 1">
      <el-form-item label="提示" class="form-item">
        <el-alert title="包邮代表所有地区都需进行配送且无需运费" type="error" :closable="false"></el-alert>
      </el-form-item>
    </template>
    <template v-else-if="dataMap.isFree === 2">
      <el-form-item label="运费规则" class="form-item" :rules="formRules.required" prop="supplierPostageDetailsModelList[0]">
        <el-alert title="除指定地区外，其余地区的运费采用【默认运费】" type="error" :closable="false"></el-alert>
        <el-table
          cell-class-name="postage-td"
          :data="dataMap.supplierPostageDetailsModelList"
          v-if="dataMap.supplierPostageDetailsModelList.length"
          style="width: 100%">
          <el-table-column
            label="区域"
            width="300">
            <template slot-scope="scope">
              <span class="color-info" v-if="scope.row.supplierPostageDetails.isDefault === 1">默认运费</span>
              <span class="color-warning" v-else-if="scope.row.supplierPostageDetails.addressType === 2">{{mapCityCounty(scope.row.designatedPostageAreas)}}</span>
              <el-popover
                placement="right"
                width="400"
                trigger="hover"
                v-else>
                <el-table :data="scope.row.designatedPostageAreas" max-height="400px">
                  <el-table-column property="province" label="省" width="150"></el-table-column>
                  <el-table-column property="city" label="市"><template slot-scope="item">{{changeAllcityList(item.row.cityList)}}</template></el-table-column>
                  <el-table-column property="areaList" label="区/县"><template slot-scope="item">{{changeAllareaList(item.row.cityList)}}</template></el-table-column>
                </el-table>
                <div class="padding-tb" slot="reference">
                  <el-form-item :inline="true" :rules="formRules.required" :prop="'supplierPostageDetailsModelList['+scope.$index+'].designatedPostageAreas[0]'">
                    {{mapTableItemProvince(scope.row.designatedPostageAreas)||'请选择区域'}}
                  </el-form-item>
                </div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column
            prop="supplierPostageDetails.oneNum"
            :label="`首(${unit})`"
            width="100">
            <div class="padding-tb" slot-scope="scope">
              <el-form-item :inline="true" :rules="formRules.postageNumber" :prop="'supplierPostageDetailsModelList['+scope.$index+'].supplierPostageDetails.oneNum'">
                <el-input v-model="dataMap.supplierPostageDetailsModelList[scope.$index].supplierPostageDetails.oneNum" type="number"></el-input>
              </el-form-item>
            </div>
          </el-table-column>
          <el-table-column
            prop="supplierPostageDetails.oneFee"
            label="首费(元)"
            width="100">
            <div class="padding-tb" slot-scope="scope">
              <el-form-item :inline="true" :rules="formRules.postageMoney" :prop="'supplierPostageDetailsModelList['+scope.$index+'].supplierPostageDetails.oneFee'">
                <el-input v-model="dataMap.supplierPostageDetailsModelList[scope.$index].supplierPostageDetails.oneFee" type="number"></el-input>
              </el-form-item>
            </div>
          </el-table-column>
          <el-table-column
            prop="supplierPostageDetails.manyNum"
            :label="`续(${unit})`"
            width="100">
            <div class="padding-tb" slot-scope="scope">
              <el-form-item :inline="true" :rules="formRules.postageNumber" :prop="'supplierPostageDetailsModelList['+scope.$index+'].supplierPostageDetails.manyNum'">
                <el-input v-model="dataMap.supplierPostageDetailsModelList[scope.$index].supplierPostageDetails.manyNum" type="number"></el-input>
              </el-form-item>
            </div>
          </el-table-column>
          <el-table-column
            prop="supplierPostageDetails.manyFee"
            label="续费(元)"
            width="100">
            <div class="padding-tb" slot-scope="scope">
              <el-form-item :inline="true" :rules="formRules.postageMoney" :prop="'supplierPostageDetailsModelList['+scope.$index+'].supplierPostageDetails.manyFee'">
                <el-input v-model="dataMap.supplierPostageDetailsModelList[scope.$index].supplierPostageDetails.manyFee" type="number"></el-input>
              </el-form-item>
            </div>
          </el-table-column>
          <el-table-column>
            <template slot-scope="scope">
              <template v-if="scope.row.supplierPostageDetails.isDefault === 1">
                <!-- 默认模板 -->
              </template>
              <template v-else>
                <el-button v-if="scope.row.supplierPostageDetails.addressType === 2" type="warning" size="mini" icon="el-icon-edit-out" @click="changeSelectedCounty(scope.$index,scope.row)" plain >编辑县</el-button>
                <el-button v-else type="primary" size="mini" icon="el-icon-edit-out" @click="showAddNew(scope.$index,scope.row.designatedPostageAreas,1)" plain >编辑省市区</el-button>
                <el-button type="danger" size="mini" icon="el-icon-delete" @click="removeItem(scope.$index, 1)" plain> 移除</el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
        <!-- <el-button class="" type="primary" size="mini" icon="el-icon-plus" @click="plusPostageItem(1)">新增城市运费规则</el-button> -->
        <!-- <el-button class="" type="warning" size="mini" icon="el-icon-plus" @click="plusPostageItem(2)">新增县域运费规则</el-button> -->
        <el-button class="" type="primary" size="mini" icon="el-icon-plus" @click="plusPostageItem(3)">新增城市运费规则</el-button>
      </el-form-item>
    </template>
    <template v-else>
    </template>
    <!-- 条件包邮 -->
    <template v-if="dataMap.isFree === 2">
      <el-form-item label="条件包邮" class="form-item">
        <el-alert title="指定地区满足件数或金额即可包邮" type="error" :closable="false"></el-alert>
        <el-table
          cell-class-name="postage-td"
          :data="dataMap.freeShippingDetailList"
          v-if="dataMap.freeShippingDetailList.length"
          style="width: 100%">
          <el-table-column
            label="区域"
            width="300">
            <template slot-scope="scope">
              <el-popover
                placement="right"
                width="400"
                trigger="hover">
                <el-table :data="scope.row.freeShippingAreaList" max-height="400px">
                  <el-table-column property="province" label="省" width="150"></el-table-column>
                  <el-table-column property="city" label="市"><template slot-scope="item">{{changeAllcityList(item.row.cityList)}}</template></el-table-column>
                  <el-table-column property="areaList" label="区/县"><template slot-scope="item">{{changeAllareaList(item.row.cityList)}}</template></el-table-column>
                </el-table>
                <div class="padding-tb" slot="reference">
                  <el-form-item :inline="true" :rules="formRules.required" :prop="'freeShippingDetailList['+scope.$index+'].freeShippingAreaList[0]'">
                    {{mapTableItemProvince(scope.row.freeShippingAreaList)||'请选择区域'}}
                  </el-form-item>
                </div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column
            prop="fullNum"
            label="件数(个)"
            width="100">
            <div class="padding-tb" slot-scope="scope">
              <el-form-item :inline="true" :rules="formRules.postageNumber" :prop="'freeShippingDetailList['+scope.$index+'].fullNum'">
                <el-input v-model="dataMap.freeShippingDetailList[scope.$index].fullNum" type="number"></el-input>
              </el-form-item>
            </div>
          </el-table-column>
          <el-table-column
            prop="fullAmount"
            label="金额(元)"
            width="100">
            <div class="padding-tb" slot-scope="scope">
              <el-form-item :inline="true" :rules="formRules.postageMoney" :prop="'freeShippingDetailList['+scope.$index+'].fullAmount'">
                <el-input v-model="dataMap.freeShippingDetailList[scope.$index].fullAmount" type="number"></el-input>
              </el-form-item>
            </div>
          </el-table-column>
          <el-table-column>
            <template slot-scope="scope">
              <template>
                <el-button type="primary" size="mini" icon="el-icon-edit-out" @click="showAddNew(scope.$index,scope.row.freeShippingAreaList,2)" plain >编辑省市区</el-button>
                <el-button type="danger" size="mini" icon="el-icon-delete" @click="removeItem(scope.$index, 2)" plain> 移除</el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
        <el-button class="" type="primary" size="mini" icon="el-icon-plus" @click="pushPinkageCondition">添加包邮条件</el-button>
      </el-form-item>
    </template>
    <!-- 暂不发货地区 -->
    <template>
      <el-form-item label="暂不发货地区" class="form-item">
        <el-table
          cell-class-name="postage-td"
          :data="[{}]"
          style="width: 50%">
          <el-table-column
            label="区域"
            width="300">
            <template slot-scope="scope">
              <el-popover
                placement="right"
                width="400"
                trigger="hover">
                <el-table :data="dataMap.nonDeliveryAreaList" max-height="400px">
                  <el-table-column property="province" label="省" width="150"></el-table-column>
                  <el-table-column property="city" label="市"><template slot-scope="item">{{changeAllcityList(item.row.cityList)}}</template></el-table-column>
                  <el-table-column property="areaList" label="区/县"><template slot-scope="item">{{changeAllareaList(item.row.cityList)}}</template></el-table-column>
                </el-table>
                <div class="padding-tb" slot="reference">
                  <el-form-item :inline="true"  :prop="'nonDeliveryAreaList['+scope.$index+']'">
                    {{mapTableItemProvince(dataMap.nonDeliveryAreaList)||'请选择暂不发货区域'}}
                  </el-form-item>
                </div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot-scope="scope">
              <template>
                <el-button type="primary" size="mini" icon="el-icon-edit-out" @click="showAddNew(scope.$index,dataMap.nonDeliveryAreaList,3)" plain >编辑省市区</el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
    </template>
    <!-- 指定发货地区 -->
    <template v-if="dataMap.isFree === 1">
      <el-form-item label="指定发货地区" class="form-item">
        <el-table
          cell-class-name="postage-td"
          :data="[{}]"
          style="width: 50%">
          <el-table-column
            label="区域"
            width="300">
            <template slot-scope="scope">
              <el-popover
                placement="right"
                width="400"
                trigger="hover">
                <el-table :data="dataMap.deliveryAreaList" max-height="400px">
                  <el-table-column property="province" label="省" width="150"></el-table-column>
                  <el-table-column property="city" label="市"><template slot-scope="item">{{changeAllcityList(item.row.cityList)}}</template></el-table-column>
                  <el-table-column property="areaList" label="区/县"><template slot-scope="item">{{changeAllareaList(item.row.cityList)}}</template></el-table-column>
                </el-table>
                <div class="padding-tb" slot="reference">
                  <el-form-item :inline="true"  :prop="'deliveryAreaList['+scope.$index+']'">
                    {{mapTableItemProvince(dataMap.deliveryAreaList)||'请选择指定发货区域'}}
                  </el-form-item>
                </div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot-scope="scope">
              <template>
                <el-button type="primary" size="mini" icon="el-icon-edit-out" @click="showAddNew(scope.$index,dataMap.deliveryAreaList,4)" plain >编辑省市区</el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
    </template>
    <el-form-item>
      <el-button type="primary" @click="submitForm('formNameAddNew','edit')" v-if="dataMap.id">保存</el-button>
      <el-button type="primary" @click="submitForm('formNameAddNew','new')" v-else>提交</el-button>
      <el-button @click="resetForm('formNameAddNew')">重置</el-button>
    </el-form-item>

    <select-region :selectType="selectType" :value="editData" :visible.sync="showSelectRegion" @confirm="selectChangeRegionList"></select-region>

    <el-dialog title="选择县"
               :visible.sync="dialogCountyVisible"
               width="400px"
               :modal="true"
               :append-to-body="true"
               :modal-append-to-body="true">
      <el-alert title="很抱歉，这里只能单选某个区县~" type="error" :closable="false"></el-alert>
      <el-cascader class="width-all"
                   ref="selectCounty"
                   style="width: 300px;"
                   placeholder="选择发货地区"
                   v-model="selectCountyValue"
                   :props="{multiple: true,checkStrictly:false,expandTrigger: 'hover',clearable:true,lazy: true,value:'name',label:'name',lazyLoad}"></el-cascader>
      <div class="text-center margin-tb">
        <el-button type="info" @click="dialogCountyVisible = false">取消</el-button>
        <el-button type="primary" @click="confirmCounty">确认</el-button>
      </div>
    </el-dialog>

  </el-form>
</template>

<script>

  import {URL} from '../../../config'
  import * as formRules from '../../../assets/js/formRules'
  import selectRegion from './selectRegion'
  import {isEmpty,deepsClone} from '../../../assets/js/utils'
  import {isPostageFreeArray,isPostageBillingTypeArray} from '../../../assets/js/status'

  function returnBaseData(){
    return {
      billingType: 1,
      createTime: 0,
      isFree: 1, // 默认包邮
      name: "",
      shipTime: 1,
      supplierId: 0,
      updateTime: 0,
      supplierPostageDetailsModelList: [], // 运费规则数据
      freeShippingDetailList: [], // 包邮条件数据
      nonDeliveryAreaList:[], //暂不发货地区
      deliveryAreaList:[], // 指定发货地区 *和暂不发货地区只能一个里面有数据
      addrJson:{
        "area": "",
        "areaCode": 0,
        "city": "",
        "cityCode": 0,
        "province": "",
        "provinceCode": 0,
      },
    }
  }
  export default {
    name: 'editPostage',
    props:{
      data:Object,
      type:{
        type:Number,
        default:1,
      },
      tableData:{
        type:Array
      },
      userType:{
        type:String,
        default:'supplier',// admin-总后台  supplier-商家
      },
    },
    components:{
      selectRegion
    },
    data(){
      return {
        loading:false,
        editType:1,// 0-查看，1-新增，2-编辑
        isPostageFreeArray:isPostageFreeArray,
        isPostageBillingTypeArray,
        formRules,
        // 深拷贝参数
        dataMap:returnBaseData(),
        // 发货时间
        deliveryTimeList:[],
        shipAddressValue:[],/*选择发货地址*/
        regionValue:[], // 选择省市
        selectCountyValue:[],//选择县
        editIndex:0,
        editData:[],
        showSelectRegion:false,
        dialogCountyVisible:false,
        unit:'件',
        selectType: 1, //区分选择城市区的字段 1是运费规则选的，2是条件包邮选的， 3是暂不包邮， 4是指定发货地区 ， 5是指定不发货地区
      }
    },
    created(){
      this.getDeliveryTime().finally(()=>{
        this.dataGetMap(this.data)
      })
    },
    methods:{
      /**
       * 处理 dataMap
       */
      dataGetMap(data){
        this.dataMap = isEmpty(data) ? returnBaseData() : deepsClone(data)
        let addrJson = this.dataMap.addrJson || {};
        if(typeof this.dataMap.addrJson === 'string'){
          try {
            addrJson = JSON.parse(this.dataMap.addrJson)||{};
            this.dataMap.addrJson = addrJson;
          }catch (e) {
            console.error(e);
          }
        }
        this.shipAddressValue = [addrJson.provinceCode||'',addrJson.cityCode||'',addrJson.areaCode||''];
        this.initFirstRule(); // 刷新
        this.changeUnit(this.dataMap.billingType);
        return this.dataMap
      },
      /**
       * 获取发货时间
       */
      getDeliveryTime(){
        this.loading = true
        return this.axios.get(URL.supplierPostage.getShipTime).then(res=>{
          this.deliveryTimeList = res.data
          return Promise.resolve(res)
        }).catch(res=>{
          this.deliveryTimeList = []
          return Promise.reject(res)
        }).finally(res=>{
          this.loading = false
        })
      },
      /**
       * 显示 编辑属性组弹窗
       * @param data  {object}  //编辑时需要传入编辑的数据对象
       * @param type  {number}  //编辑类型
       * */
      showAddNew(index,data,type){
        this.selectType = type
        if (type === 3 && (this.dataMap.deliveryAreaList.length != 0)) {
          this.$alert('你有已配置的指定发货地区，请清空后再配置暂不发货地区', '提示', {
            confirmButtonText: '确定',
          });
          return
        }
        if (type === 4 && (this.dataMap.nonDeliveryAreaList.length != 0)) {
          this.$alert('你有已配置的暂不发货地区，请清空后再配置指定发货地区', '提示', {
            confirmButtonText: '确定',
          });
          return
        }
        this.editIndex = index;
        if(typeof data === 'object' && (data.length != 0)){
          this.editData = JSON.parse(data[0].cityArrJson);
        }else{
          this.editData = []
        }
        this.showSelectRegion = true
      },
      removeItem(index, type){
        if (type === 1) {
          this.dataMap.supplierPostageDetailsModelList.splice(index,1)
        } else {
          this.dataMap.freeShippingDetailList.splice(index,1)
        }
        
      },
      // 编辑县按钮-暂废弃
      changeSelectedCounty(index,data){
        this.editIndex = index;
        this.dialogCountyVisible = true;
        if(data && data.designatedPostageAreas.length){
          let addr = data.designatedPostageAreas[0];
          this.selectCountyValue = [addr.province,addr.city[0],addr.county[0]];
        }else {
          this.selectCountyValue = [];
        }
      },
      confirmCounty(){
        let result = this.$refs.selectCounty.getCheckedNodes()[0];
        let pathLabels = result.pathLabels;
        let [province,city,county] = pathLabels;
        this.dataMap.supplierPostageDetailsModelList[this.editIndex].designatedPostageAreas = [{'province':province,'city':[city],'county':[county]}];
        this.dialogCountyVisible = false;
      },
      /**
       * 发货地址 省市区获取
       */
      lazyLoad(node, resolve) {
        let levelMax = 3;
        let data = {}
        if (node.level === 0) {
          data.parentId = 0
        } else {
          data.parentId = node.data.id
        }
        if(node.level >= levelMax) return resolve();
        this.axios.get(URL.region.list, {
          params: data
        }).then(res => {
          if (node.level >= (levelMax - 1)) res.data.forEach(item => {
            item.leaf = true
          });
          resolve(res.data)
        }).catch(() => {
          resolve([])
        })
      },
      changeSelectRegion(ids){
        let result = this.$refs.shipAddressValue.getCheckedNodes()[0];
        let pathLabels = result.pathLabels;
        let [provinceCode,cityCode,areaCode] = ids;
        let [province,city,area] = pathLabels;
        this.dataMap.addrJson = {
          provinceCode,
          province,
          cityCode,
          city,
          areaCode,
          area,
        }
      },
      /**
       * 运费规则-省市区选择
       */
      selectChangeRegionList(newValue, type){
        console.log('选择省市区弹出返回的数据', newValue, type);
        if (type === 1) {
          this.dataMap.supplierPostageDetailsModelList[this.editIndex].designatedPostageAreas = newValue
        } else if (type === 2) {
          this.dataMap.freeShippingDetailList[this.editIndex].freeShippingAreaList = newValue
        } else if (type === 3) {
          this.dataMap.nonDeliveryAreaList = newValue
        } else if (type === 4) {
          this.dataMap.deliveryAreaList = newValue
        }
        // 选择完毕后判断是否有冲突模块
           // 统计运费规则选择的城市id
        let list1 = [];
        this.dataMap.supplierPostageDetailsModelList.forEach((item, index) => {
          if (item.designatedPostageAreas.length != 0) {
            let arr = JSON.parse(item.designatedPostageAreas[0].cityArrJson)
            arr.forEach(item1 => {
              list1.push(item1[2])
            })
          }
        })
        console.log('运费规则中地址选中的三级数据=>', list1);
        let list2 = [];
        this.dataMap.freeShippingDetailList.forEach((item, index) => {
          if (item.freeShippingAreaList.length != 0) {
            let arr = JSON.parse(item.freeShippingAreaList[0].cityArrJson)
            arr.forEach(item1 => {
              list2.push(item1[2])
            })
          }
        })
        console.log('包邮条件中地址选中的三级数据=>', list2)
        let list3 = [];
        if (this.dataMap.nonDeliveryAreaList.length != 0) {
          if (this.dataMap.nonDeliveryAreaList[0].length != 0) {
              let arr = JSON.parse(this.dataMap.nonDeliveryAreaList[0].cityArrJson)
              arr.forEach(item1 => {
                list3.push(item1[2])
              })
          }
        }
        console.log('暂不发货中地址选中的三级数据=>', list3)
        let list4 = [];
        if (this.dataMap.deliveryAreaList.length != 0) {
          if (this.dataMap.deliveryAreaList[0].length != 0) {
              let arr = JSON.parse(this.dataMap.deliveryAreaList[0].cityArrJson)
              arr.forEach(item1 => {
                list3.push(item1[2])
              })
          }
        }
        console.log('允许发货中地址选中的三级数据=>', list4)
        let mergeList1 = list1.concat(list2);
        let mergeList2 = list3;
        let isRepetition = false;
        for (var i = 0; i < mergeList1.length; i++) {
          if (mergeList2.indexOf(mergeList1[i]) > -1) {
            isRepetition = true
            break
          }
        }
        if (isRepetition) {
          this.$notify.error({
            title: '提示',
            message: '条件包邮及运费规则中的地址和暂不发货地区中地址有重复，请重新选择保存',
            type: 'warning'
          });
        } else {
          this.showSelectRegion = false
        }

      },
      // 初始化运费
      initFirstRule(){
        if(this.dataMap.supplierPostageDetailsModelList.length === 0){
          this.dataMap.supplierPostageDetailsModelList.push({
            "designatedPostageAreas": [],
            "supplierPostageDetails": {
              "manyNum": 1,
              "oneNum": 1,
              "isDefault": 1,
            }
          })
        }
        if(!this.dataMap.freeShippingDetailList){
          this.dataMap.freeShippingDetailList = [];
        }
        if(!this.dataMap.nonDeliveryAreaList){
          this.dataMap.nonDeliveryAreaList = [];
        }
        if(!this.dataMap.deliveryAreaList) {
          this.dataMap.deliveryAreaList = [];
        }
      },
      changeUnit(newValue = 1){
        switch (newValue) {
          case 1:
            this.unit = '件';
            break;
          case 2:
            this.unit = '千克';
            break;
          case 3:
            this.unit = '立方';
            break;
        }
      },
      /**
       * 新增运费规则
       */
      plusPostageItem(addressType = 1){
        console.log('地址类型', addressType);
        this.dataMap.supplierPostageDetailsModelList.push({
          "designatedPostageAreas": [], //城市数据
          "supplierPostageDetails": {   //城市配送信息数据
            "manyNum": 1,
            "oneNum": 1,
            "isDefault": 2,
            "addressType": addressType,//地址类型 1-省市 2-县级
          }
        })
      },
      // 新增包邮条件
      pushPinkageCondition() {
        this.dataMap.freeShippingDetailList.push({
          "freeShippingAreaList": [], // 城市数据
          "fullAmount": '', //满多少金额包邮
          "fullNum": '' // 满多少数量包邮
        })
      },
      // 转化选中的城市用于展示
      changeAllcityList(data) {
        let list = []
        data.map(item => {
          list.push(item.city)
        })
        return list.join(',')
      },
      // 转化选中的区县用于展示
      changeAllareaList(data) {
        let list = []
        data.map(item => {
          list.push(item.areaList.join(','))
        })
        return list.join(',')
      },
      /**
       * 拼接省级列表文本
       * @param arr
       * @returns {*}
       */
      mapTableItemProvince(arr){
        if(!((arr || []).length)) return ''
        let maxLength = 4;
        let names = arr.map(res=>res.province)
        if(names.length > maxLength){
          names.splice(maxLength,names.length - maxLength)
          return names.join() + ' ......'
        }else {
          return names.join()
        }
      },
      mapCityCounty(arr){
        if(!((arr || []).length)) return '';
        let addr = arr[0];
        return addr.city[0] + '/' + addr.county;
      },
      cutIsFree(tag) {
        if (tag == 1) {
          //包邮
          this.$confirm('此操作会清空自定义邮费运费相关配置, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.dataMap.isFree = 1;
            this.dataMap.supplierPostageDetailsModelList = [];
            this.dataMap.freeShippingDetailList = [];
          }).catch(() => {     
            this.dataMap.isFree = 2  
          });
        } else {
          //自定义
          this.$confirm('此操作会清空包邮相关配置, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.dataMap.isFree = 2;
            this.dataMap.nonDeliveryAreaList = [];
            this.dataMap.deliveryAreaList = [];
          }).catch(() => {      
            this.dataMap.isFree = 1
          });
        }
      },
      /**
       * 判断提交参数
       */
      judgeSubmitParams(){
        let data = this.dataMap;
        if(data.isFree === 1){
          return true
        }else{
          let judge = !!this.dataMap.supplierPostageDetailsModelList.length
          if(judge){
            judge = this.dataMap.supplierPostageDetailsModelList.every(res=>{
              if(res.supplierPostageDetails.isDefault === 1) return true;
              if(res.designatedPostageAreas.length){
                // debugger
                // return true
                return res.designatedPostageAreas.every(item => !!item.cityList.length)
              }else{
                return false
              }
            })
          }
          if(!judge) this.$message.error('自定义规则未选择区域')
          return judge
        }
      },
      /**
       * 拼接请求参数
       */
      joinSubmitParams(){
        let data = deepsClone(this.dataMap)
        if(data.isFree === 1){
          data.supplierPostageDetailsModelList = []
        }else{
          data.supplierPostageDetailsModelList = deepsClone(this.dataMap.supplierPostageDetailsModelList)
        }
        return data;
      },
      /**
       * 新增
       */
      addNew(submitType){
        if(!this.judgeSubmitParams()) return false
        let isEdit = submitType === 'edit'
        let typeText=  isEdit ? '保存' : '新增'
        let data = this.joinSubmitParams()
        this.loading = true;
        this.axios.post(URL.supplierPostage.saveSupplierPostage,data).then(res=>{
          this.$emit('success',{data,type:'save',msg:typeText + '成功'})
        }).catch(res=>{
          this.deliveryTimeList = []
        }).finally(res=>{
          this.loading = false
        })
      },
      /**
       * 表单提交及重置
       * @param formName
       * @param type    {string}    new-新增 edit-修改
       */
      submitForm(formName,submitType) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let dataList = this.tableData;
            if(this.dataMap.id){
              dataList = dataList.filter(res=>res.id !== this.dataMap.id);
            }
            let name = this.dataMap.name;
            let dl = dataList.map(res=>res.name).concat(name);
            dl = dl.filter(res=>res === name);
            if(dl.length > 1){
              this.$message.error(`已存在相同的模板名称${name}`);
              return false;
            }
            this.addNew(submitType)
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
        this.dataMap ={
            billingType: 1,
            createTime: 0,
            isFree: 1, // 默认包邮
            name: "",
            shipTime: 1,
            supplierId: 0,
            updateTime: 0,
            supplierPostageDetailsModelList: [], // 运费规则数据
            freeShippingDetailList: [], // 包邮条件数据
            nonDeliveryAreaList:[], //暂不发货地区
            deliveryAreaList:[], // 指定发货地区 *和暂不发货地区只能一个里面有数据
            addrJson:{
              "area": "",
              "areaCode": 0,
              "city": "",
              "cityCode": 0,
              "province": "",
              "provinceCode": 0,
            },
        }
        this.deliveryTimeList = [];
        this.shipAddressValue=[];/*选择发货地址*/
        this.regionValue=[]; // 选择省市
        this.selectCountyValue=[];//选择县
        this.editIndex=0;
        this.editData=[];
        this.showSelectRegion=false;
        this.dialogCountyVisible=false;
        this.unit='件';
        this.selectType= 1 //区分选择城市区的字段 1是运费规则选的，2是条件包邮选的， 3是暂不包邮， 4是指定发货地区 ， 5是指定不发货地区
      },

    },
    watch:{
      data:function (newValue) {
        this.dataGetMap(newValue)
      },
      'dataMap.isFree':function(newValue){
        if(newValue === 2){
          this.initFirstRule();
        }
      },
      'dataMap.billingType':function (newValue) {
        this.changeUnit(newValue);
      },
    }
  }
</script>

<style lang="less">
  .postage-td{
    padding: 0 !important;
  }
</style>
<style lang="less" scoped>
  @import (reference) "../../../assets/css/data";
  .textarea-text{
    box-sizing: border-box;
    width: 300px;
    border: @border-width-solid solid @border-color-one;
    min-height: 100px;
  }
</style>
