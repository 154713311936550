<!--省市区选择组件-->
<template>
  <div class="">
    <el-dialog title="选择省市"
               :visible.sync="dialogVisible"
               @closed="closed"
               width="600px"
               :modal="true"
               :append-to-body="true"
               :modal-append-to-body="true">
      <el-cascader-panel filterable
                         v-model="regionValue"
                         :options="regionList"
                         :props="regionListProps"></el-cascader-panel>
      <div class="text-center margin-tb">
        <el-button type="info" @click="cancel">取消</el-button>
        <el-button type="primary" @click="confirm">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {URL} from '../../../config'
  import {isEmpty} from '../../../assets/js/utils'

  export default {
    name: 'selectRegion',
    props:{
      value:Array, //编辑需要的数据
      visible:Boolean,  // 是不是展示
      selectType:Number, // 那个模块用的
    },
    data(){
      return {
        dialogVisible:false,
        // 省市
        regionListIdObject:{},
        regionListNameObject:{},
        regionList:[],
        regionValue:[],
        regionListProps:{
          multiple:true,
          value:'id',
          label:'text'
        },
      }
    },
    created(){
      if(this.visible) this.dialogVisible = this.visible
      this.regionValue = this.value;
      // this.getRegionList()
      this.provinceCityCounty()
    },
    methods:{
      /**
       * 获取省市列表（旧的）
       */
      // getRegionList(){
      //   this.loading = true
      //   this.axios.get(URL.region.provinceCity).then(res=> {
      //     res.data.forEach(res => {
      //       if (!this.regionListIdObject[res.id]){
      //         this.regionListIdObject[res.id] = res
      //       }
      //       if (!this.regionListNameObject[res.text]){
      //         this.regionListNameObject[res.text] = res
      //       }
      //     })
      //     this.deleteNullChildren(res.data)
      //     this.regionList = res.data
      //   }).catch(res=>{
      //     this.regionList = []
      //   }).finally(res=>{
      //     this.loading = false
      //   })
      // },
      /**
       * 获取省市区列表（新优化）
       */
      provinceCityCounty(){
        this.loading = true
        this.axios.get(URL.region.provinceCityCounty).then(res=> {
          res.data.forEach(res => {
            if (!this.regionListIdObject[res.id]){
              this.regionListIdObject[res.id] = res
            }
            if (!this.regionListNameObject[res.text]){
              this.regionListNameObject[res.text] = res
            }
          })
          this.deleteNullChildren(res.data)
          this.regionList = res.data
        }).catch(res=>{
          this.regionList = []
        }).finally(res=>{
          this.loading = false
        })
      },
      deleteNullChildren(list){
        return list.forEach(res=>{
          if(res.children instanceof Array && res.children.length){
            this.deleteNullChildren(res.children)
          }else {
            delete res.children
          }
        })
      },
      /**
       * 省市区选择
       */
      selectChangeRegionList(newValue){
      },
      /**
       * 关闭动画结束
       */
      closed(){
        this.$emit('closed',...arguments)
      },
      /**
       *  将 选中的单层列表数据[[parentId,childrenId]...] 处理成为 多级数据列表
       */
      disposeDataRegionValue(list = this.regionValue){
        // let li = [ ['parentId-1','childrenId-1'],['parentId-1','childrenId-2'] ]
        // >>>
        // let li = [{ "province": "parentId-1", "city": ["childrenId-1","childrenId-2"]}]
        let parentIds = Array.from(new Set(list.map(res=>res[0]))) // 所有选中的一级城市id

        let newList = parentIds.map(id=>{
          let sonIds = list.filter(res=>res[0] === id).map(res=>res[1])
          let countyIds = list.filter(res=>res[0] === id).map(res=>res[2]) // 所有选中的三级城市id
          let item = this.regionListIdObject[id];
          // console.log('源数据===>', list)
          console.log('所有选中的一级城市id===>', parentIds);
          console.log('所有选中的二级城市id===>', new Set(sonIds));
          console.log('所有选中的三级城市id===>', new Set(countyIds));
          let cityList = [];
          item.children.filter(son=>sonIds.includes(son.id)).map((res)=>{
            let cityName = res.text
            let countyTexts = [];
            res.children.filter(countyId=>countyIds.includes(countyId.id)).map((res1)=>{
              countyTexts.push(res1.text)
            })
            cityList.push({
              city: cityName,
              areaList: countyTexts
            })
          })
          return {
            'province': item.text,
            'cityList': cityList,
            'cityArrJson': JSON.stringify(list)
          }
        })
        return newList
      },
      /**
       *  将 多级数据列表 处理成为 可被选中的单层列表数据[[parentId,childrenId]...]-旧版二级城市转化函数-赞废弃
       */
      // disposeParamsValue(list = this.value){
      //   let result = []
      //   if(!(list instanceof Array)) return result;
      //   if(isEmpty(list)) return result
      //   list = list.map(res=>{
      //     let name = res.province
      //     let item = this.regionListNameObject[name];
      //     if(isEmpty(item)){
      //       return result
      //     }
      //     return {
      //       id: item.id,
      //       city: [].concat(res.city),
      //       children:item.children
      //     }
      //   })
      //   list.forEach(item=>{
      //     if(isEmpty(item.city)) return false
      //     let childrenNames = item.children.map(res=>res.text)
      //     item.city.forEach(city=>{
      //       let index = childrenNames.indexOf(city)
      //       if(index === -1) return false
      //       result.push([item.id,item.children[index].id])
      //     })
      //   })
      //   return result
      // },

      // 新的三级城市转化函数
      disposeParamsValue(list = this.value){
        console.log('即将转化的数据==========>', list)
        let result = list
        return result
      },
      /**
       * 确认、取消按钮
       */
      confirm(){
        let data = this.disposeDataRegionValue()
        // this.$emit('input',data)
        this.$emit('confirm',data, this.selectType)
        // this.cancel()
      },
      cancel(){
        this.dialogVisible = false
        this.$emit('cancel')
      },
    },
    watch:{
      visible(newValue){
        console.log('watch visible',newValue)
        this.dialogVisible = newValue
      },
      dialogVisible(newValue){
        console.log('watch dialogVisible',newValue)
        this.$emit('update:visible',newValue)
      },
      value(newValue){
        console.log('watch value',newValue)
        this.regionValue = this.disposeParamsValue(newValue)
        this.$emit('input',newValue)
      },
      regionValue(newValue){
        console.log('watch regionValue',newValue)
        this.$emit('input',newValue)
      },
    }
  }
</script>

<style lang="less" scoped>

</style>
